<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
               <div class="form-body">
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <md-field :class="{'md-invalid': submitted && $v.entity.companyCode.$error }">
                                        <label for="companyCode">Mã công ty</label>
                                        <md-input name="companyCode" v-model="entity.companyCode"></md-input>
                                        <span class="md-error" v-if="submitted && !$v.entity.companyCode.required">Vui lòng nhập mã công ty</span>
                                    </md-field>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <md-field :class="{'md-invalid': submitted && $v.entity.companyAlias.$error }">
                                        <label for="companyAlias">Tên viết tắt</label>
                                        <md-input name="companyAlias" v-model="entity.companyAlias"></md-input>
                                        <span class="md-error" v-if="submitted && !$v.entity.companyAlias.required">Vui lòng nhập tên viết tắt</span>
                                    </md-field>
                                </div>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                               <label for="phone">Số điện thoại</label>
                               <md-input name="phone" v-model="entity.phone" ></md-input>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="fax">Fax</label>
                                <md-input name="fax" v-model="entity.fax" ></md-input>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="fax">Số tài khoản</label>
                                <md-input name="fax" v-model="entity.bankNumber" ></md-input>
                            </md-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.companyName.$error }">
                                <label for="companyName">Tên công ty</label>
                                <md-input name="companyName" v-model="entity.companyName"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.companyName.required">Vui lòng nhập tên công ty</span>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="email">Email</label>
                                <md-input name="email" v-model="entity.email" ></md-input>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="taxCode">Mã số thuế</label>
                                <md-input name="taxCode" v-model="entity.taxCode" ></md-input>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="fax">Ngân hàng</label>
                                <md-input name="fax" v-model="entity.bankBranch" ></md-input>
                            </md-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-6 m-6 c-12">
                            <md-field>
                               <label for="address">Địa chỉ</label>
                               <md-input name="address" v-model="entity.address" ></md-input>
                            </md-field>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <md-field>
                                <label for="note">Ghi chú</label>
                                <md-input name="note" v-model="entity.note" ></md-input>
                            </md-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   </div>
</template>
<script>
    import companyService from '../../../api/companyService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '../../../mixins';

    export default ({
        metaInfo: {
            title: 'Cập nhật thông tin công ty'
        },
        data() {
            return {
               title: '',
               id: 0,
               submitted: false,
               entity: { id: 0, companyCode: '', companyAlias: '', companyName: '', phone: '', fax: '', address: '', email: '', note: '', typeCode: '', companyType: common.companyType.owner, isActive: true },
            }
        },
        created(){
            this.title = 'Cập nhật thông tin công ty';
               this.getOwner();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.edit();
            },

            edit(){
                this.setLoading(true);
                companyService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getOwner(){
                this.setLoading(true);
                companyService.getOwner().then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            }
        },
        validations: {
            entity: {
                companyCode: { required },
                companyAlias: { required },
                companyName: { required }
            }
       }
     })

</script>
